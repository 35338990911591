import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import { Button, Grid } from "@mui/material";
I18n.putVocabularies(translations);
I18n.setLanguage("ja");

const searchParams = new URLSearchParams(window.location.search);
const userPoolId = searchParams.get("upi");
const userPoolWebClientId = searchParams.get("upwci");
const tenantId = searchParams.get("ti");
console.log(userPoolId);
console.log(userPoolWebClientId);
console.log(tenantId);

if (!userPoolId || !userPoolWebClientId || !tenantId) {
  throw new Error("Must be a upi and upwci and ti");
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USERPOOLWEBCLIENTID,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
    secondary: {
      main: "#00bcd4",
    },
  },
});

const authServices = {
  async handleSignUp(formData: any) {
    let { username, password, attributes } = formData;
    console.log(formData);
    attributes["custom:tenantId"] = tenantId;
    return Auth.signUp({
      username,
      password,
      attributes,
      autoSignIn: { enabled: true },
    });
  },
};

const AccountManager = () => {
  console.log("Called Account Manager");
  return (
    <div style={{ padding: 100 }}>
      <h1 style={{ textAlign: "center" }}>
        Radio Wave Monitoring PF Account Manager
      </h1>
      <Authenticator
        services={authServices}
        loginMechanisms={["email"]}
        signUpAttributes={["email"]}
        initialState="signUp"
      >
        {({ signOut, user }) => (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item xs={12}>
              <p style={{ textAlign: "center" }}>
                こんにちは、 {user?.username} さん。ログインに成功しました。
              </p>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" onClick={signOut}>
                サインアウト
              </Button>
            </Grid>
          </Grid>
        )}
      </Authenticator>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <AccountManager />
      </Authenticator.Provider>
    </ThemeProvider>
  );
}
